.aside {
    width: 81px;
    position: fixed;
    background: #2d3748;
    border-right: 1px solid hsla(0,0%,100%,.21);
    padding: 0;
    margin-top: 66px;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
    z-index: 99;
    float:left;
}

.logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.logo img {
}

.menu {
    float: left;
    width: 100%;
}

.sidebar ul {
    width: 100%!important;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    flex: 1 1;
}
.sidebar ul li.logo{
    padding:0px !important;
}
.sidebar ul li.logo a{
    padding: 15px 0px 0px 0px!important;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    display: block;
}
.sidebar ul li.logo a img{
    width:50px;
}
.sidebar ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 81px;
    padding: 15px 0;
}
.sidebar ul li:hover{
    background: #1a202c;
}
.sidebar ul li a{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.sidebar ul li a svg{
    color:#ffffff;
    width:20px;
    margin-bottom: 5px;
}
.sidebar ul li a span {
    color: #ffffff;
    font-size:12px;
    
}

.sidebar ul li:hover a {
    background-size: 100% 100%;
}

.header {
    background: #2d3748;
    display: flex;
    align-items: center;
    border-bottom: 1px solid hsla(0,0%,100%,.21);
    width: 100%;
    height: 66px;
    justify-content: space-between;
    padding: 0 24px;
    top: 0;
    position: fixed;
    z-index: 9999;
    float:left;
}

.header .logo {
    justify-content: flex-start;
}

.header .profile {
    justify-content: flex-end;
}

button:focus, button:hover, button:active {
    outline: none !important;
    box-shadow: none !important;
}
.aside+.right_container {
    width: calc(100% - 81px);
    transition: .5s;
    
}