body{
    background: #1a202c;
}
ul {
    margin: 0 !important;
    padding: 0;
}

li {
    list-style: none;
}

a, a:hover {
    text-decoration: none !important;
}

.right_container {
    position: relative;
    float:right;
}
.heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}
.heading h4{
    color:#ffffff;
    margin:0;
    font-size:20px;
    font-style: normal;
    font-weight: normal;
}
.heading ul li {
    float: left;
    margin-left: 10px;
}
.select-events {
    width:100%;
    float:left;
}


.heading ul li img {
    width: 20px;
}
.heading ul li a svg{
    margin-right:5px;
    width: 18px;
    margin-left: -5px;
}
.heading ul li a {
    background: transparent;
    border:1px solid #ffffff3d;
    height: 35px;
    padding:5px 10px;
    color:#ffffff;
    font-size:14px;
    font-style: normal;
    font-weight: normal;
    border-radius:4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard {
    float: left;
    width: 100%;
    padding: 66px 20px 0 20px;
    min-height: calc(100vh - 0px);
    box-sizing: border-box;
    background: #1a202c;
}

.list .item {
    float: left;
    width: 25%;
    height: 300px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.list .item a {
    float: left;
    width: 100%;
    text-align: center;
    color: #333940;
    text-decoration: none;
    font-size: 20px;
    font-weight: 300;
}

.list .item a h2 {
    font-size: 30px;
    font-weight: 300;
}


/***** Slide View ********/

.ReactModal__Content.ReactModal__Content--after-open.slide-pane.slide-pane_from_right {
    width: calc(100% - 80px) !important;
}

.static {
    pointer-events: none;
}

td ul {
    display: flex;
    flex-direction: row;
}

td ul li {
    margin-right: 10px;
}

td ul li:last-child {
    margin-right: 0;
}

.table-responsive {
    margin-top: 70px;
    max-height: calc(100vh - 130px);
}

.pagination {
    float: left;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
}

.array{
    margin: 50px 0;
    border: solid 1px #000;
    padding: 5px;
    text-transform: capitalize;
}

.object{
    margin: 50px 0;
    border: solid 1px #000;
    padding: 5px;
    text-transform: capitalize;
}
.no-border{
    border: none!important;
}

.object .form-label, .array .form-label{
    display: block;
}

.editorClassName{
    max-height: 500px;
    text-transform: none;
}

/* Header Style */
.userProfile{
    position: relative;
}
.userProfile img{
    width:40px;
    cursor: pointer;
}
.header-drop-down{
    background: #2d3748;
    position: absolute;
    top: 41px;
    width: 150px;
    border: 1px solid hsla(0,0%,100%,.21);
    right:0;
}
.header-drop-down ul{
    margin:0;
    padding: 0;
}
.header-drop-down ul li{
    list-style: none;
}
.header-drop-down ul li a{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: hsla(0,0%,100%,.5);
    padding: 8px 12px;
    text-transform: capitalize;
    border-top: 0.5px solid hsla(0,0%,100%,.21);
    display: flex;
    align-items: center;
}

/* Inner Pages Style */
.inner-content {
    width: 100%;
    float: left;
    padding: 20px 15px;
    background: #2d3748;
    margin:20px 0;
    border-radius:4px;
}


/* Table Style */
.commen-table {
    width: 100%;
    float: left;
    padding-top: 30px;
}

.commen-table .MuiPaper-root{
    background-color: transparent;
    box-shadow: none;
}
.commen-table table thead tr th{
    background: #f9fbfc;
    border: none;
    color: #6f6f6f;
    font-weight: 700;
    line-height: 16px;
    font-size: 14px;
    padding: 15px 28px;
}
.commen-table  table {
    border: 1px solid #ffffff3d;
    border-radius: 2px;
}

.commen-table table tbody tr td{
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    vertical-align: middle;
    text-overflow: ellipsis!important;
    border: none!important;
    background: transparent;
    padding: 15px 28px;
    border-top:1px solid #ffffff3d !important;
}
.commen-table table tbody tr td a{
    color:#ffffff;
}
.responsive-table table>:not(:first-child) {
    border-top: none;
}
.commen-table table tbody td ul{
    margin:0;
    padding:0;
}
.commen-table table tbody td ul li a{
    /* border:1px solid #ffffff3d;
    padding:5px; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}
.commen-table table tbody td ul li a svg{
    width:16px;
    color:#ffffff;
}


/* Form Style */
.commen-form {
    width: 100%;
    float: left;
    padding: 15px 10px;
}
.commen-form  h4{
    font-size: 16px;
    color:#ffffff;
    margin-bottom: 15px;
}
.commen-form form .col-md-4{
    margin-bottom: 1rem;
}
.commen-form form  .form-label{
    font-size: 14px;
    color:#ffffff;
    margin-bottom: 5px;
}
.commen-form form .form-control{
    background: #ffffff;
    border:1px solid #ffffff;
    border-radius: 2px;
    height: 40px;
    padding: 0 0.5rem;
    width: 100%;
    color: #000000;
    box-shadow: none;
    font-size: 14px;
    font-weight: 300;
}
.commen-form form .form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #00000066;
}
.commen-form form .form-control::-moz-placeholder { /* Firefox 19+ */
    color: #00000066;
}
.commen-form form .form-control:-ms-input-placeholder { /* IE 10+ */
    color: #00000066;
}
.commen-form form .form-control:-moz-placeholder { /* Firefox 18- */
    color: #00000066;
}
.commen-form form .css-1s2u09g-control{
    background: #ffffff;
    border:1px solid #ffffff;
    border-radius: 2px;
    height: 40px;
    font-size: 14px;
    font-weight: 300;
}
.commen-form form  .css-tlfecz-indicatorContainer{
    color:#212529;
}
.commen-form form .css-1okebmr-indicatorSeparator{
    display: none;
}
.info-boxes {
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid #ffffff33;
}
.commen-form .react-datepicker__input-container input{
    background: transparent;
    border:1px solid #ffffff33;
    border-radius: 2px;
    height: 40px;
    padding: 0 0.5rem;
    width: 100%;
    color: #ffffff33;
    font-size: 14px;
    font-weight: 300;
}
.commen-form .css-qc6sy-singleValue{
    color:#ffffff;
    font-size: 14px;
    font-weight: 300;
}
.commen-form .css-14el2xx-placeholder{
    color: #ffffff33;
    font-size: 14px;
    font-weight: 300;
}
.Form-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.Form-buttons button.cancel{
    background: transparent !important;
    border:none !important;
    color:#ffffff;
    margin-right: 15px;
    font-size:14px;
    font-weight: 300;
}
.Form-buttons button.submit{
    background: #ffffff !important;
    border:none !important;
    color:#000000;
    font-size:14px;
    font-weight: 400;
    border-radius:2px;
    height:36px;
    padding:0 10px;
    line-height: 1;
}
.konvajs-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
.template-boxes {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.template-image-box {
    width: 70%;
}
.template-right {
    display: flex;
    flex-direction: column;
    width:30%;
}
.picker-label {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}
.picker-label span{
    font-size: 14px;
    color:#ffffff;
    margin-bottom: 5px;
}
.picker-label input{
    background: transparent;
    border:1px solid #ffffff33;
    border-radius: 2px;
    height: 40px;
    padding: 0 0.5rem;
    width: 100%;
    color: #ffffff;
    box-shadow: none;
    font-size: 14px;
    font-weight: 300;
}
.browse {
    color: #000000 !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    background: #ffffff !important;
    height: 28px;
    padding: 0 10px !important;
    border: none;
    border-radius: 2px !important;
    margin-right:15px !important;
}
.add-label {
    color: #000000 !important;
    text-transform: capitalize !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    background: #ffffff !important;
    height: 28px;
    padding: 0 10px !important;
    border: none;
    border-radius: 2px !important;
    margin-right:15px !important;
}
.certificate-heading h1{
    color: #ffffff;
    margin: 0;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
}
.certificate-add .form .form-label {
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 5px;
}
.certificate-add .form .col-md-4{
    margin-bottom: 1rem;
}
.certificate-add .form .form-control {
    background: #ffffff;
    border: 1px solid #ffffff33;
    border-radius: 2px;
    height: 40px;
    padding: 0 0.5rem;
    width: 100%;
    color: #000000;
    box-shadow: none;
    font-size: 14px;
    font-weight: 300;
}
.certificate-add .form select{
    height: 40px;
    border: none;
    background: #ffffff;
    width: 100%;
    padding: 0 10px;
    color: #000000;
    font-size: 14px;
    font-weight: 300;
}
.download-button {
    background: #ffffff;
    margin-top: 20px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 2px;
    color:#000000;
}
.Form-buttons-certificate {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.Form-buttons-certificate button.cancel-certificate{
    background: transparent;
    border: none;
    font-size: 14px;
    font-weight: 400;
    color:#ffffff;
    margin-right:15px;
}
.Form-buttons-certificate .MuiButton-root{
    background: #ffffff;
    margin-top: 20px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    font-size: 14px;
    font-weight: 400;
    border-radius: 2px;
    padding: 0 10px;
    color:#000000;
    text-transform: capitalize;
}

/* Login Screen Style */
.login-screen{
    background: #1a202c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:100%;
    height:100vh;
}
.login-box {
    width: 462px;
    padding: 25px 30px;
    margin: 0px auto;
    border: 1px solid #8692a6;
    box-sizing: border-box;
    border-radius: 4px;
}
.login-heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.login-box h2{
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 26px;
    color: #4299e1;
    margin-top: 0px;
}
.login-box  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #ffffff;
    margin-top: 8px;
}
.login-form input{
    background: transparent;
    border:1px solid #8692a6;
    border-radius: 2px;
    color:#ffffff;
    font-size: 14px;
    font-weight: 400;
    height:40px;
    padding: 0 10px;
    width:100%;
}
.login-form a{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #4299e1;
}
.login-form .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    display: none;
}
/* .login-form button{
    background: #ffffff !important;
    height:38px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    color:#000000;
    width:100%;
    margin:15px auto 0 auto;
    text-transform: capitalize;
} */
.inner-content.csv .CSVImporter_Importer{
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px auto 0 auto;
}
.inner-content.csv  .CSVImporter_FileSelector{
    border: 1px dashed #ffffff;
    padding: 4em;
    border-radius: 0.4em;
    background: transparent;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    width:100%;
}
.inner-content.csv  .CSVImporter_ImporterFrame{
    float: left;
    width: 100%;
}
.select-events label{
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 5px;
}
.inner-content.csv  .CSVImporter_FormatRawPreview{
    display: none;
}
.inner-content.csv button.CSVImporter_TextButton {
    background: transparent !important;
    border: 1px solid #1a202c;
    height: 32px;
    padding: 0 10px;
    margin: 0;
    border-radius: 3px;
}
.confirm.text-white {
    width: 100%;
    float: left;
    margin-top: 15px;
}
.result.text-white {
    width: 100%;
    float: left;
    margin-top: 15px;
}
.result.text-white  p{
    margin-bottom: 8px;
}
.processing.text-white {
    width: 100%;
    float: left;
    margin-top: 15px;
}

.heading-for-certificate {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 40px;
}
.heading-for-certificate h2{
    color: #ffffff;
    margin: 0;
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
}
.heading-for-certificate p{
    color: #ffffff;
    margin: 30px auto;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    width:70%;

}
.heading-for-certificate ul{
    margin:0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.heading-for-certificate ul li {
    float: left;
    margin-left: 15px;
}
.heading-for-certificate ul li a {
    background: transparent;
    border: 1px solid #ffffff3d;
    height: 35px;
    padding: 5px 10px;
    color: #ffffff;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.heading-for-certificate ul li a svg {
    margin-right: 5px;
    width: 18px;
    margin-left: -5px;
}

.resource-person {
    width: 100%;
    float: left;
    margin: 20px 0;
}
.resource-person h4 {
    color: #ffffff;
    margin-bottom: 10px;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
}
.add-more-field {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.add-more-field button{
    border:none;
    background: #ffffff;
    height:28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#000000;
    font-size:14px;
    width:auto;
    padding: 0 10px;
    margin:10px 0px 0px 10px;
    border-radius: 2px;
}
.add-more-field button img{
    width:18px;
    margin-right:10px;
}
.view-values{
    display: flex;
    align-items: center;
}
.view-values label {
    color:#ffffff;
    font-size: 14px;
}
.view-values span {
    color:#ffffff;
    font-size: 16px;
    padding-left: 5px;
}
